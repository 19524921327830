export const popularPositionList = {
  title: 'Popüler Meslekler',
  items: [
    {
      icon: 'icon-most-popular-21',
      text: 'Şoför/Sürücü',
      url: 'sofor-surucu',
    },
    {
      icon: 'icon-most-popular-739',
      text: 'Güvenlik Görevlisi',
      url: 'guvenlik-gorevlisi',
    },
    // {
    //   icon: 'icon-most-popular-444',
    //   text: 'Çağrı Merkezi Elemanı',
    //   url: 'cagri-merkezi-elemani',
    // },
    {
      icon: 'icon-most-popular-467',
      text: 'Kurye',
      url: 'kurye',
    },
    {
      icon: 'icon-most-popular-1033',
      text: 'Kasiyer',
      url: 'kasiyer',
    },
    {
      icon: 'icon-most-popular-1878',
      text: 'Tezgahtar',
      url: 'tezgahtar',
    },

    // {
    //   icon: 'icon-most-popular-226',
    //   text: 'Garson',
    //   url: 'garson',
    // },
    // {
    //   icon: 'icon-most-popular-177',
    //   text: 'Sekreter',
    //   url: 'sekreter',
    // },
    // {
    //   icon: 'icon-most-popular-1863',
    //   text: 'Temizlik Görevlisi',
    //   url: 'temizlik-gorevlisi',
    // },
    // {
    //   icon: 'icon-most-popular-23',
    //   text: 'Aşçı',
    //   url: 'asci',
    // },
  ],
}
export const popularServiceList = {
  title: 'Popüler Hizmetler',
  items: [
    {
      icon: 'icon-cleaning',
      text: 'Temizlik',
      url: 'temizlik',
    },
    {
      icon: 'icon-renovation',
      text: 'Tadilat',
      url: 'tadilat',
    },
    {
      icon: 'icon-transport',
      text: 'Nakliyat',
      url: 'nakliyat',
    },
    {
      icon: 'icon-lesson',
      text: 'Özel Ders',
      url: 'ozel-ders',
    },
    {
      icon: 'icon-repairs',
      text: 'Tamir',
      url: 'tamir',
    },
    {
      icon: 'icon-food',
      text: 'Yemek',
      url: 'yemek',
    },
  ],
}
export const homePopularServiceSection = [
  {
    positionId: 12164,
    positionName: 'Ev Temizliği',
  },
  {
    positionId: 12084,
    positionName: 'Tadilat',
  },
  {
    positionId: 11994,
    positionName: 'Boya Badana',
  },
  {
    positionId: 12169,
    positionName: 'Ofis Temizliği',
  },
  {
    positionId: 11839,
    positionName: 'Evden Eve Nakliyat',
  },
  {
    positionId: 11868,
    positionName: 'Motorlu Kurye',
  },
  {
    positionId: 580,
    positionName: 'Elektrikçi',
  },
  {
    positionId: 11787,
    positionName: 'Yemek & Catering',
  },
  {
    positionId: 12158,
    positionName: 'Apartman Temizliği',
  },
  {
    positionId: 12089,
    positionName: 'Temizlik & İlaçlama',
  },
  {
    positionId: 11935,
    positionName: 'Çocuk Bakımı & Ev Yardımcısı',
  },
  {
    positionId: 11932,
    positionName: 'Bebek Bakımı',
  },
]

export const popularServiceSection = [
  {
    id: 11839,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/evden-eve-nakliyat.png`,
    title: 'Evden Eve Nakliyat',
    providerNumber: '12.000',
    url: 'evden-eve-nakliyat',
    icon: 'icon-local-shipping',
  },
  {
    id: 12164,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/ev-temizligi.png`,
    title: 'Ev Temizliği',
    providerNumber: '170.000',
    url: 'ev-temizligi',
    icon: 'icon-cleaning-services',
  },
  {
    id: 580,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/elektrikci.png`,
    title: 'Elektrikçi',
    providerNumber: '58.000',
    url: 'elektrikci',
    icon: 'icon-electrical-services',
  },
  {
    id: 11787,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/yemek-catering.png`,
    title: 'Yemek & Catering',
    providerNumber: '4.000',
    url: 'yemek-ve-catering',
    icon: 'icon-dining',
  },
  {
    id: 11868,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/motorlu-kurye.png`,
    title: 'Motorlu Kurye',
    providerNumber: '80.000',
    url: 'motorlu-kurye',
    icon: 'icon-delivery-dining',
  },
  {
    id: 12089,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/temizlik-ilaclama.png`,
    title: 'Temizlik & İlaçlama',
    providerNumber: '160.000',
    url: 'temizlik-ve-ilaclama',
    icon: 'icon-sanitizer',
  },
  {
    id: 11932,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/bebek-bakimi.png`,
    title: 'Bebek Bakımı',
    providerNumber: '40.000',
    url: 'bebek-bakimi',
    icon: 'icon-child-friendly',
  },
  {
    id: 12084,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/tadilat.png`,
    title: 'Tadilat',
    providerNumber: '13.000',
    url: 'tadilat',
    icon: 'icon-hardware',
  },
  {
    id: 11994,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/boya-badana.png`,
    title: 'Boya Badana',
    providerNumber: '13.500',
    url: 'boya-badana',
    icon: 'icon-format-paint',
  },
  {
    id: 12169,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/ofis-temizligi.png`,
    title: 'Ofis Temizliği',
    providerNumber: '170.000',
    url: 'ofis-temizligi',
    icon: 'icon-cleaning-services',
  },
  {
    id: 12158,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/apartman-temizligi.png`,
    title: 'Apartman Temizliği',
    providerNumber: '162.000',
    url: 'apartman-temizligi',
    icon: 'icon-cleaning-services',
  },
  {
    id: 11935,
    image: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/HomeServices/cocuk-bakimi.png`,
    title: 'Çocuk Bakımı & Ev Yardımcısı',
    providerNumber: '40.000',
    url: 'cocuk-bakimi-ve-ev-yardimcisi',
    icon: 'icon-child-care',
  },
]

export const popularServices = [
  { id: 11839, name: 'Evden Eve Nakliyat' },
  { id: 12164, name: 'Ev Temizliği' },
  { id: 580, name: 'Elektrikçi' },
  { id: 12084, name: 'Tadilat' },
  { id: 11994, name: 'Boya Badana' },
]

export const homeBannerChipItems = [
  { type: 'job', name: 'Garson', url: '/garson' },
  { type: 'job', name: 'Şoför/Sürücü', url: '/sofor-surucu' },
  { type: 'job', name: 'Aşçı', url: '/asci' },
  { type: 'job', name: 'Barista', url: '/barista' },
  { type: 'job', name: 'Araçlı Kurye', url: '/aracli-kurye' },
  { type: 'job', name: 'Bulaşıkçı', url: '/bulasikci' },
  { type: 'location', name: 'İstanbul', url: '/istanbul' },
  { type: 'location', name: 'İzmir', url: '/izmir' },
  { type: 'location', name: 'Ankara', url: '/ankara' },
  { type: 'location', name: 'Bursa', url: '/bursa' },
  { type: 'location', name: 'Antalya', url: '/antalya' },
  { type: 'location', name: 'Kocaeli', url: '/kocaeli' },
]
